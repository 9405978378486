import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import styles from '../bannerModule.scss';
import OnlineCatalogIcon from '../../icons/online_catalog_icon';
import IconArrowExtraSmallRight from '../../icons/icon_arrow_extra_small_right';
import DataStore from '../../../stores/DataStore';

const OnlineCatalog = inject('dataStore')(
    observer((props: { dataStore?: DataStore }) => {
        const { dataStore } = props;
        const withVat = dataStore?.mainStore.orderModuleStore.withVat;
        const onlineCatalog = withVat
            ? dataStore?.bannerModule.onlineCatalogIncVat
            : dataStore?.bannerModule.onlineCatalogExVat;

        if (!dataStore?.bannerModule.showOnlineCatalog || !onlineCatalog) {
            return null;
        }

        function openCorrectOnlineCatalogPage(): string | undefined {
            if (dataStore?.bannerModule.isOnRightPage) return onlineCatalog?.rightPage.redirectUrl;
            return onlineCatalog?.leftPage.redirectUrl;
        }

        function getAltTagValue(): string {
            if(!dataStore)
                return '';            
    
            return dataStore.mainStore.localization.generalL10n.onlineCatalog;
        }

        return (
            <div
                data-testid='online_catalog_banner'
                className={classNames(styles.banner_item, styles.online_catalog_banner)}
            >
                <div className={styles.header}>
                    <OnlineCatalogIcon />
                    <p className={styles.header_banner_type}>
                        {dataStore.mainStore.localization.generalL10n.onlineCatalog}
                    </p>
                </div>
                <div className={styles.media_content}>
                    <a href={onlineCatalog.leftPage.redirectUrl} target='_blank' rel='noreferrer noopener'>
                        <img className={styles.left_page} src={onlineCatalog.leftPage.previewImageUrl} 
                        alt={getAltTagValue()} />
                    </a>
                    <a href={onlineCatalog.rightPage.redirectUrl} target='_blank' rel='noreferrer noopener'>
                        <img className={styles.right_page} src={onlineCatalog.rightPage.previewImageUrl} 
                        alt={getAltTagValue()} />
                    </a>
                </div>
                <div data-testid='online_catalog_button' className={styles.footer}>
                    <a
                        className={styles.button}
                        href={openCorrectOnlineCatalogPage()}
                        target='_blank'
                        rel='noreferrer noopener'
                    >
                        <div className={styles.title}>{dataStore.mainStore.localization.generalL10n.discoverNow}</div>
                        <IconArrowExtraSmallRight />
                    </a>
                </div>
            </div>
        );
    })
);

export default OnlineCatalog;
