import { observable, makeObservable, action, computed } from 'mobx';
import { ILocalization } from '../types';
import DataStore from './DataStore';
import OrderModuleStore from './OrderModuleStore';
import { MixedMediaStore } from './MixedMediaStore';
import { VariantStore } from './VariantStore';
import WorkWearStoreStore from './WorkWearStoreStore';
import {
    IGlovesRecommendation,
    IResponseData,
    IVariantSelection,
    IVariantChangedEventData,
    IBookmarkArticleDataResponse,
    ArticleDataInjectionRequest,
    LogoCreatorSuccessResponse,
    LogoCreatorOpenFromBasketModalPayload,
    LogoCreatorSuccessArticle,
    IBasketModalForLogoCreator,
    IBasketModalForLogoCreatorSizeVariant,
    IDisclaimer,
    IPiwikReporting,
} from './types';
import { Fetch } from './Fetch';
import { ScrollLock } from '../helper/scrollLock';
import TrackingHelper from '../helper/trackingHelper';
import { AASFragmentHelper } from '../helper/aasFragmentHelper';
import defaultStyles from '../styles/defaults.scss';
import { EsAppHelper } from '../helper/esAppHelper';

// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var _paq: any;

const minDeskTopWidth = parseInt(defaultStyles.breakpointMobileMinWidth);
const bookmarkIntegrationSignature = 'ESCID.ESPP.ProductDetailsPage.Bookmark';
const bookmarkRequestEventName = 'ESCID.ESPP.Bookmark.RequestArticleDataInjection';
const bookmarkResponseEventName = 'ESCID.ESPP.Bookmark.ArticleDataInjection';
const logoCreatorIntegrationSignature = 'ESCID.ESPP.LogoCreator';
const logoCreatorDidAddToBasketEventName = 'ESPP.LogoCreator.DidAddToBasket';
const basketModalContinueDesignEventName = 'ESPP.BasketModal.OpenLogoCreator';

export class MainStore {
    public dataStore: DataStore;
    public orderModuleStore: OrderModuleStore;
    public mixedMediaStore: MixedMediaStore;
    public variantStore: VariantStore;
    public workWearStoreStore: WorkWearStoreStore;
    public readonly scrollLock = new ScrollLock();
    public readonly aasFragmentHelper = new AASFragmentHelper();
    public localization: ILocalization;
    public fetch: Fetch;
    public readonly additionalDescription3DContainerId = 'ThreeSixtyView_Additional_Description';
    public readonly esAppHelper = new EsAppHelper();
    public readonly redesignOrderModal: boolean;
    public readonly piwikProEnableEcommerceTracking: boolean;
    public readonly enableMetaPixel: boolean;
    public readonly afterLoginParam: string = 'openLogoCreator';
    public readonly afterLoginParamWithValue: string = this.afterLoginParam + '=true';
    public readonly esBrand = 'engelbert strauss';

    // @observable:
    public fetchedData: IResponseData;
    public cdnAssetsUrl: string;
    public glovesRecommendation: IGlovesRecommendation[];
    public hasGlovesRecommendation: boolean;
    public hasProductFinderShutter: boolean;
    public hasAASShutter: boolean;
    public isMobileShop: boolean;
    public isEsApp: boolean;
    public isGlobal: boolean;
    public showKioskLayout: boolean;
    public wwsId;
    public poIsVisible: boolean;
    public confirmRegistrationHintIsVisible: boolean;
    public debuggerIsOn: boolean;
    public stickyHeaderTop: number;
    public articleSoldOut: boolean;
    public trackingHelper: TrackingHelper;

    public checkSelectedSizeType(variantSelection: IVariantSelection) {
        const isFirstSelectedTypeSoldOut =
            variantSelection.availableSizeTypes.find(sizeType => {
                if (sizeType.sizeTypeName == variantSelection.selectedSizeType)
                    return sizeType.sizes.every(size => {
                        return size.salesArticleVariantKey == '';
                    });
            }) != null;

        if (isFirstSelectedTypeSoldOut) {
            const nonSoldOutSizeType = variantSelection.availableSizeTypes.find(sizeType => {
                return !sizeType.sizes.every(size => {
                    return size.salesArticleVariantKey == '';
                });
            });
            if (nonSoldOutSizeType) {
                variantSelection.selectedSizeType = nonSoldOutSizeType.sizeTypeName;
                variantSelection.selectedSize = nonSoldOutSizeType.sizes.find(size => {
                    return size.salesArticleVariantKey;
                })?.size;
            }
        }
    }

    constructor(initialData: IResponseData, localization: ILocalization) {
        // if value not actual, then article has sold out state:
        this.articleSoldOut = initialData.state && initialData.state.name.toLowerCase() !== 'actual';
        this.isGlobal = initialData.contextData.portal.includes('.GLOBAL.');
        this.localization = localization;

        //hardcode price disclaimer for GLB. In future will be removed. Just tmp solution
        this.insertDisclaimerForGLB(initialData);

        this.dataStore = new DataStore(initialData, this);
        this.isMobileShop = initialData.contextData.isMobile;
        this.isEsApp = initialData.contextData.isEsApp;

        this.fetchedData = initialData;
        this.mixedMediaStore = new MixedMediaStore(this);

        // If all sizes in selected size type are sold out
        // change first selected size type and first selected size
        this.checkSelectedSizeType(initialData.variantSelection);
        this.checkSelectedSizeType(this.dataStore.variantSelection);

        this.variantStore = new VariantStore(this);
        this.fetch = new Fetch(this.fetchedData.contextData.originalRootPath);
        this.orderModuleStore = new OrderModuleStore(this, initialData.contextData.priceMode);
        this.workWearStoreStore = new WorkWearStoreStore(this);

        this.glovesRecommendation = initialData.shutterModule.glovesRecommendation;
        this.hasGlovesRecommendation = initialData.shutterModule.hasGlovesRecommendation;

        // TODO: remove this and its usage after QuickOrderModal has been proper integrated into legacy
        this.redesignOrderModal = initialData.contextData.featureToggles.redesignOrderModal;

        this.cdnAssetsUrl = initialData.contextData.baseUrlForAssets;
        this.wwsId = initialData.contextData.wwsId;
        this.showKioskLayout = this.wwsId != null;
        this.poIsVisible = false;
        this.confirmRegistrationHintIsVisible = false;
        this.hasProductFinderShutter =
            initialData.purchaseAdvice.showJacketFinder || initialData.purchaseAdvice.showTrouserFinder ||
            initialData.purchaseAdvice.showShoeFinder;
        this.hasAASShutter = false;
        this.debuggerIsOn = false;

        this.piwikProEnableEcommerceTracking = initialData.contextData.featureToggles.piwikProEnableEcommerceTracking;
        this.enableMetaPixel = initialData.contextData.featureToggles.enableMetaPixel;

        this.stickyHeaderTop = 0;
        this.trackingHelper = new TrackingHelper(this.piwikProEnableEcommerceTracking,
            this.enableMetaPixel, this.esBrand);

        makeObservable(this, {
            fetchedData: observable,
            cdnAssetsUrl: observable,
            showKioskLayout: observable,
            glovesRecommendation: observable,
            hasGlovesRecommendation: observable,
            hasProductFinderShutter: observable,
            hasAASShutter: observable,
            debuggerIsOn: observable,
            wwsId: observable,
            poIsVisible: observable,
            confirmRegistrationHintIsVisible: observable,
            stickyHeaderTop: observable,
            setStickyHeaderTop: action,
            showPersonalizeOverlay: action,
            showPersonlizeRegistrationConfirmHint: action,
            getRightPosition: action,
            variantChanged: action,
            setFetchedData: action,
            setHasAASShutter: action,
            turnOnDebugger: action,
            isPurchaseAdviceAvailable: computed,
            getProductFinderLogo: computed,
            getProductFinderTitle: computed,
            getProductFinderSubTitle: computed,
            getProductFinderContentTitle: computed,
            getProductFinderContentDescription: computed,
            getProductFinderRedirectUrl: computed,
            getProductFinderRedirectBtnText: computed,
            getBookmarkContainerID: computed,
            getProductFinderName: computed
        });

        this.initializeTrackerEventsOnPageLoad(initialData);

        if (typeof window !== 'undefined' && window.shell) {
            window.shell.subscribeTo(
                bookmarkRequestEventName,
                (request: ArticleDataInjectionRequest) => {
                    if (request.requester === this.getBookmarkContainerID) {
                        const response: IBookmarkArticleDataResponse = {
                            ...this.fetchedData.bookmark,
                            requester: request.requester,
                        };
                        response.categoryName = this.dataStore.breadcrumb.category.name;
                        window.shell.publishTo(bookmarkResponseEventName, response);
                    }
                },
                bookmarkIntegrationSignature
            );
            if (this.dataStore.contextData.featureToggles.enableLogoCreator &&
                (this.dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable ?? false)) {
                window.shell.subscribeTo(
                    logoCreatorDidAddToBasketEventName,
                    (payload: LogoCreatorSuccessResponse) => {
                        const exampleSavKey = payload.articles[0]?.savKey;

                        if (payload.masterArticleNumber === this.dataStore.orderModule.articleInfo.masterArticleNo) {
                            // still same articles with same master article number
                            if (this.dataStore.orderModule.articleInfo.salesArticleVariantKey
                                !== exampleSavKey) {
                                // trigger variant change, for different sav key
                                const exampleSavKeyStrings = exampleSavKey.split('-');
                                this.variantStore.variantChange(true, exampleSavKeyStrings[0],
                                    exampleSavKeyStrings[1], exampleSavKeyStrings[2]);
                                window.shell.once('ESPP.DetailPage.PageUpdate', () => {
                                    this.basketModalDataForLogoCreator(payload.reporting, payload.itemOrigin,
                                        payload.articles, payload.smakeId, this.fetchedData).then(newReturnValue => {
                                            window.shell.publishTo('ESPP.BasketModal.Open.LogoCreator', newReturnValue);
                                            this.trackingHelper.metaTrackAddToCartSmake(payload, newReturnValue);
                                        });

                                });
                            } else {
                                this.basketModalDataForLogoCreator(
                                    payload.reporting,
                                    payload.itemOrigin, payload.articles, payload.smakeId, this.fetchedData)
                                    .then(returnValue => {
                                        window.shell.publishTo('ESPP.BasketModal.Open.LogoCreator', returnValue);
                                    });  
                            }
                        } else {
                            // different articles, master article number changed.
                           
                            if (exampleSavKey) {
                                const salesArticleVariantKey = exampleSavKey.split('-');
                                this.fetch.fetch(
                                    salesArticleVariantKey[0],
                                    salesArticleVariantKey[1],
                                    payload.masterArticleNumber,
                                    salesArticleVariantKey[2]).then((response: IResponseData) => {
                                        if (response) {
                                            const returnValue = this.basketModalDataForLogoCreator(payload.reporting,
                                                payload.itemOrigin,
                                                payload.articles, payload.smakeId, response);
                                            window.shell.publishTo('ESPP.BasketModal.Open.LogoCreator', returnValue);
                                        }
                                    });
                            }

                        }
                    },
                    logoCreatorIntegrationSignature
                );

                window.shell.subscribeTo(
                    basketModalContinueDesignEventName,
                    (payload: LogoCreatorOpenFromBasketModalPayload) => { this.openLogoCreatorDesigner(payload.smakeId); }
                )
            }
        }

        this.showPersonalizeOverlay = this.showPersonalizeOverlay.bind(this);
        this.showPersonlizeRegistrationConfirmHint = this.showPersonlizeRegistrationConfirmHint.bind(this);
        this.openLogoCreatorDesigner = this.openLogoCreatorDesigner.bind(this);
    }

    private initializeTrackerEventsOnPageLoad(initialData: IResponseData) {
        if (typeof document !== 'undefined')
            // rare use case
            if (document.readyState !== 'loading')
                window.setTimeout(() => this.trackVisitedArticle(initialData));
            else {
                let trackingExecuted = false;
                let fallbackTimer;
                document.addEventListener('DOMContentLoaded',
                    () => {
                        // default use case for most modern browsers
                        if (typeof _paq !== 'undefined') {
                            trackingExecuted = true;
                            this.trackVisitedArticle(initialData);
                        } else
                            // fallback with high chance to track browsers where default approach doesn't work
                            fallbackTimer = window.setTimeout(() => {
                                if (typeof _paq !== 'undefined' && !trackingExecuted) {
                                        trackingExecuted = true;
                                        this.trackVisitedArticle(initialData);
                                    }
                                },
                                1250);
                        });

                // safe fallback for tracking but can be skipped by user through cancel page load on slow connections
                window.addEventListener('load', () => {
                        if (!trackingExecuted) {
                            trackingExecuted = true;
                            clearTimeout(fallbackTimer);
                            this.trackVisitedArticle(initialData);
                        }
                    });
            }
    }

    public trackVisitedArticle(data: IResponseData) {
        const caseInsensitiveParameters = new URLSearchParams();

        if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
            const originParameters = new URL(window.location.href).searchParams;
            originParameters.forEach((value: string, key: string) => {
                caseInsensitiveParameters.append(key.toLowerCase(), value);
            });
        }

        const itemOrigin = caseInsensitiveParameters.get('itemorigin') ||
            'NAVIGATION/' + this.dataStore.breadcrumb.category.name || '';

        this.trackingHelper.piwikTrackVisitedArticle(data, itemOrigin);
        this.trackingHelper.metaTrackVisitedArticle(data);
    }

    public insertDisclaimerForGLB(response: IResponseData) {
        if (this.isGlobal && response.shutterModule.productInfo.description.isKidArticle) {
            const disclaimerName = 'hardcode GLB disclaimer';
            const alreadyHasDisclaimer =
                response.shutterModule.productInfo.description.orderedDisclaimers.find((disclaimer) => {
                    return disclaimer.name === disclaimerName;
                })
            if (!alreadyHasDisclaimer) {
                const rawRtfText = 
                `<div style="display: none;" data-manipulate='{ "IE": { "css": { "display": "block"}} }'>
            ${this.localization.redOnlyL10n?.disclaimerGLB} </div>`;
                const newDisclaimer: IDisclaimer = {
                    name: disclaimerName,
                    structuredText: { id: '' },
                    rtfText: rawRtfText,
                    dataMode: 5,
                    globalRank: 1 // no clue the meaning of this, no usage
                };
                response.shutterModule.productInfo.description.orderedDisclaimers.push(newDisclaimer);
            }
        }
    }

    // action
    public variantChanged(fetchedData: IResponseData) {
        //hardcode price disclaimer for GLB. In future will be removed. Just tmp solution
        this.insertDisclaimerForGLB(fetchedData);

        // if value not actual, then article has sold out state:
        this.articleSoldOut = fetchedData.state && fetchedData.state.name.toLowerCase() !== 'actual';

        this.setFetchedData(fetchedData);
        this.dataStore.updateData(fetchedData);
        this.mixedMediaStore.dataUpdate(fetchedData.mixedMedia);
        this.trackVisitedArticle(fetchedData);
        this.updateDocumentTitle(
            this.dataStore.orderModule.articleInfo.title,
            this.dataStore.variantSelection.selectedColor?.name ?? ''
        );

        if (this.showKioskLayout && this.workWearStoreStore.wwsData) {
            this.workWearStoreStore.changeWWSData(this.workWearStoreStore.wwsData);
        }

        window.shell.publishTo('ESPP.DetailPage.PageUpdate', fetchedData);
        window.shell.publishTo('ESPP1.Detailpage.VariantChange.Public', {
            masterArticleNo: fetchedData.mixedMedia.masterArticleNo,
            colorCode: fetchedData.mixedMedia.colorCode,
        } as IVariantChangedEventData);
    }

    updateDocumentTitle(articleTitle: string, colorName: string): boolean {
        let result = false;
        if (typeof document !== 'undefined' && articleTitle) {
            document.title = [
                articleTitle,
                colorName ? colorName : '',
                '| Strauss',
            ].join(' ');
            result = true;
        }
        return result;
    }

    setFetchedData(newFetchedData: IResponseData) {
        this.fetchedData = newFetchedData;
    }

    setHasAASShutter(value: boolean) {
        this.hasAASShutter = value;
    }

    turnOnDebugger() {
        this.debuggerIsOn = !this.debuggerIsOn;
    }

    setStickyHeaderTop(): void {
        const header = typeof document !== 'undefined' && document.querySelector('.mkt-headr');
        this.stickyHeaderTop = header ? header.getBoundingClientRect().height : 50;
    }

    get isPurchaseAdviceAvailable(): boolean {
        return this.hasAASShutter || this.hasProductFinderShutter;
    }

    public getRightPosition(width: number, contentWidth: number): number {
        let result = 0;

        // 430 px is variant panel width
        // 480 order module
        // dividing by 2, space from left right

        // total spaces needed:
        const mixedMediaAndOrderModuleWidth = contentWidth + 480 + 430;

        if (width <= 1820) {
            // ESPP-4849, small view should not trigger content right shift
            if (width >= minDeskTopWidth) result = 430;
        } else {
            const tmpRemainingSpace = width - mixedMediaAndOrderModuleWidth;
            result = tmpRemainingSpace >= 430 ? 90 : (430 - tmpRemainingSpace) / 2;
        }

        return result;
    }

    public updateUrl(urlString) {
        // https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
        history.replaceState(null, '', urlString);
    }

    public hideEsAppButton() {
        this.esAppHelper.navigationWheel.hide();
    }

    public showEsAppButton() {
        this.esAppHelper.navigationWheel.show();
    }

    public openLogoCreatorDesigner(smakeId?: number): void {
        if (!this.dataStore.contextData.customerData?.customerNumbers) {
            this.showPersonlizeRegistrationConfirmHint(true);
        } else {
            if (typeof window !== 'undefined' && window.shell)
                window.shell.publishTo('ESPP.LogoCreator.OpenDesignerWindow',
                    {
                        externalIdentifier: `${this.dataStore.orderModule.articleInfo.salesArticleVariantKey}`,
                        masterArticleNumber: `${this.dataStore.orderModule.articleInfo.masterArticleNo}`,
                        navKey: `${this.dataStore.breadcrumb.category.navigationKey}`,
                        navKeyPath: `${this.dataStore.breadcrumb.category.navigationKeyPath}`,
                        userId: this.dataStore.contextData.customerData.customerNumbers[0],
                        quantity: this.orderModuleStore.articleCount,
                        withVat: this.orderModuleStore.withVat,
                        smakeId: smakeId,
                        preselectedArticles: [{
                            variant: {
                                id: smakeId,
                                external_identifier: `${this.dataStore.orderModule.articleInfo.salesArticleVariantKey}`,
                            },
                            quantity: this.orderModuleStore.articleCount
                        }]
                    });
        }
    }

    public onPersonalizeIntent(personalizeOverlay = true): void {

        if (!(this.dataStore.contextData.featureToggles.enableLogoCreator &&
            (this.dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable ?? false)))
            window.open(this.dataStore.bannerModule.logoServiceRedirectUrl, '_self');

        if (this.fetchedData.contextData.customerData?.ssoId) {
            //if user is logged in

            if (personalizeOverlay) {
                //close this PO modal
                this.showPersonalizeOverlay(false);
            }

            this.openLogoCreatorDesigner();
        } else {
            // user not logged in yet
            //2. after AMS is done switching, open it 
            window.shell.subscribeTo('ESCRM.AccountMenu.AccountMenuSwitchedToLoginRequestedMode', () => {
                //this opens the flyout
                window.shell.publishTo('ESMKT.HeadR.ToggleFlyout', 'LoginFlyout');
                if (personalizeOverlay) {
                    //close this PO modal
                    this.showPersonalizeOverlay(false);
                }
            });

            // 1. request AMS to switch its text to a message defined by us
            const currentPage: string = window.location.pathname + window.location.search;
            const redirectUrl = currentPage + (currentPage.indexOf('?') >= 0 ? '&' : '?') + this.afterLoginParamWithValue;

            if (this.isEsApp) {
                const urlWithParamEncoded = encodeURIComponent(redirectUrl);
                let param = '?';
                if(this.dataStore.contextData.featureToggles.useNewAccountService) {
                    param += 'loginRequired=true&redirectUrl='
                } else {
                    param += 'loginSuccessForwardPath='
                }
                param += urlWithParamEncoded;
                let loginForEsAppWithRedirect = '';
                if(this.dataStore.contextData.featureToggles.useNewAccountService) {
                    loginForEsAppWithRedirect += this.localization.generalL10n.esAppLoginPageUrl 
                } else {
                    loginForEsAppWithRedirect += this.localization.generalL10n.esAppOldLoginPageUrlLogoCreator
                }
                loginForEsAppWithRedirect += param;
                window.location.href = loginForEsAppWithRedirect;
                return;
            }

            window.shell.publishTo('ESCRM.AccountMenu.ServiceRequestsLogin',
                {
                    redirectUrl: redirectUrl,
                    message: this.localization.generalL10n.personalizationLoginHint,
                });
        }
    }

    public showPersonalizeOverlay(show: boolean) {
        // only do the change if needed
        if (show !== this.poIsVisible) {
            if (show && !this.poIsVisible) {
                // open
                this.scrollLock.lock();
                this.hideEsAppButton();
            } else {
                // close
                this.scrollLock.unlock();
                this.showEsAppButton();
            }
            this.poIsVisible = !this.poIsVisible;
        }
    }

    public showPersonlizeRegistrationConfirmHint(show: boolean) {
        // only do the change if needed
        if (show !== this.confirmRegistrationHintIsVisible) {
            if (show && !this.confirmRegistrationHintIsVisible) {
                // open
                this.scrollLock.lock();
                this.hideEsAppButton();
            } else {
                // close
                this.scrollLock.unlock();
                this.showEsAppButton();
            }
            this.confirmRegistrationHintIsVisible = !this.confirmRegistrationHintIsVisible;
        }
    }

    get getProductFinderLogo(): string | undefined {
        if (this.dataStore.purchaseAdvice.showJacketFinder) return this.localization.redOnlyL10n?.jacketFinderLogo;
        if (this.dataStore.purchaseAdvice.showTrouserFinder) return this.localization.redOnlyL10n?.trouserFinderLogo;
        if (this.dataStore.purchaseAdvice.showShoeFinder) return this.localization.redOnlyL10n?.shoeFinderLogo;
    }

    get getProductFinderTitle(): string | undefined {
        if (this.dataStore.purchaseAdvice.showJacketFinder) return this.localization.redOnlyL10n?.jacketFinderTitle;
        if (this.dataStore.purchaseAdvice.showTrouserFinder) return this.localization.redOnlyL10n?.trouserFinderTitle;
        if (this.dataStore.purchaseAdvice.showShoeFinder) return this.localization.redOnlyL10n?.shoeFinderTitle;
    }

    get getProductFinderSubTitle(): string | undefined {
        if (this.dataStore.purchaseAdvice.showJacketFinder) return this.localization.redOnlyL10n?.jacketFinderSubTitle;
        if (this.dataStore.purchaseAdvice.showTrouserFinder) return this.localization.redOnlyL10n?.trouserFinderSubTitle;
        if (this.dataStore.purchaseAdvice.showShoeFinder) return this.localization.redOnlyL10n?.shoeFinderSubTitle;
    }

    get getProductFinderContentTitle(): string | undefined {
        if (this.dataStore.purchaseAdvice.showJacketFinder)
            return this.localization.redOnlyL10n?.findYourPerfectJacketIn3SimpleSteps;
        if (this.dataStore.purchaseAdvice.showTrouserFinder)
            return this.localization.redOnlyL10n?.findYourPerfectTrousersIn3SimpleSteps;
        if (this.dataStore.purchaseAdvice.showShoeFinder)
            return this.localization.redOnlyL10n?.justaFewStepsToThePerfectFootwear;
    }

    get getProductFinderContentDescription(): string | undefined {
        if (this.dataStore.purchaseAdvice.showJacketFinder)
            return this.localization.redOnlyL10n?.areYouLookingForThePerfectJacket;
        if (this.dataStore.purchaseAdvice.showTrouserFinder)
            return this.localization.redOnlyL10n?.areYouLookingForThePerfectWorkTrousers;
        if (this.dataStore.purchaseAdvice.showShoeFinder)
            return this.localization.redOnlyL10n?.areYouLookingForThePerfectShoe;

    }

    get getProductFinderRedirectUrl(): string {
        if (this.dataStore.purchaseAdvice.showJacketFinder)
            return this.dataStore.purchaseAdvice.jacketFinderRedirectUrl;
        if (this.dataStore.purchaseAdvice.showTrouserFinder)
            return this.dataStore.purchaseAdvice.trouserFinderRedirectUrl;
        else (this.dataStore.purchaseAdvice.showShoeFinder)
        return this.dataStore.purchaseAdvice.shoeFinderRedirectUrl;
    }

    get getProductFinderRedirectBtnText(): string | undefined {
        if (this.dataStore.purchaseAdvice.showJacketFinder)
            return this.localization.redOnlyL10n?.goToJacketFinder;
        if (this.dataStore.purchaseAdvice.showTrouserFinder)
            return this.localization.redOnlyL10n?.goToTrouserFinder;
        if (this.dataStore.purchaseAdvice.showShoeFinder)
            return this.localization.redOnlyL10n?.goToShoeFinder;
    }

    get getBookmarkContainerID(): string {
        return this.isMobileShop ? 'dp-bookmark-add-button-mobile' : 'dp-bookmark-add-button-desktop';
    }

    get getProductFinderName(): string | undefined {
        if (this.dataStore.purchaseAdvice.showJacketFinder)
            return 'jf';
        if (this.dataStore.purchaseAdvice.showTrouserFinder)
            return 'tf';
        if (this.dataStore.purchaseAdvice.showShoeFinder)
            return 'sf';
    }

    // helper:
    async basketModalDataForLogoCreator(
        reporting: IPiwikReporting,
        itemOrigin: string,
        sizes: Array<LogoCreatorSuccessArticle>,
        smakeId: number,
        fetchedData: IResponseData): Promise<IBasketModalForLogoCreator> {
        const sizeVariants: Array<IBasketModalForLogoCreatorSizeVariant> = []; 
        if (sizes.length === 1) {
            const sizeVariant: IBasketModalForLogoCreatorSizeVariant = {
                savKey: sizes[0].savKey,
                quantity: sizes[0].quantity,
                netPrice: fetchedData.orderModule.articlePrice.basicPrice.netValue,
                label: this.filterSizeLabelWithSavKey(fetchedData, sizes[0].savKey),
            }
            sizeVariants.push(sizeVariant);
        } else {
            const sizeSavKeys = sizes.map(size => size.savKey);
            const sizePrice = await this.fetch.getPriceListForSizes(fetchedData.contextData.originalRootPath,
                sizeSavKeys);
            sizes.forEach(size => {
                const sizeVariant: IBasketModalForLogoCreatorSizeVariant = {
                    savKey: size.savKey,
                    quantity: size.quantity,
                    netPrice: sizePrice[size.savKey],
                    label: this.filterSizeLabelWithSavKey(fetchedData, size.savKey),
                }
                sizeVariants.push(sizeVariant);
            });
        }

        return {
            title: fetchedData.orderModule.articleInfo.title,
            color: fetchedData.variantSelection.selectedColor?.code.toString(),
            colorName: fetchedData.variantSelection.selectedColor?.name,
            sizesWithPrice: sizeVariants,
            imageUrl: fetchedData.mixedMedia.image?.fullPath,
            exampleSalesVariantKey: sizes[0].savKey,
            model: fetchedData.variantSelection.selectedModel?.code.toString(),
            modelLabel: fetchedData.variantSelection.selectedModel?.label,
            smakeId: smakeId,
            masterArticleNumber: fetchedData.orderModule.articleInfo.masterArticleNo,
            isStraussBrand: fetchedData.orderModule.articleInfo.brand ?
                fetchedData.orderModule.articleInfo.brand.toLowerCase() === this.esBrand : false,
            nativeNavKeyPath: fetchedData.breadcrumb.category.navigationKeyPath,
            reporting: reporting,
            itemOrigin: itemOrigin,
            categoryName: fetchedData.breadcrumb.category.name
        };
    }

    private filterSizeLabelWithSavKey(fetchedData: IResponseData, savKey: string): string {
        const sizeTypes = fetchedData.variantSelection.availableSizeTypes;
        let label = '';
        sizeTypes.forEach(sizeType => {
            const match = sizeType.sizes.find((value) => value.salesArticleVariantKey === savKey);
            if (match) {
                label = match.size.name;
                return;
            }
        });

        return label;
    }
}
