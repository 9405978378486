import React, { useEffect, useState } from 'react';
import styles from './productInfoArticleSets.scss';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../types';
import * as helper from '../../helper/html-helper';
import ProductAdditionalLayout from '../productInfo/additionalLayout/productAdditionalLayout';
import { RichText } from '../../helper/richText';
import DetailsModal from './detailsModal/detailsModal';
import { IArticleSetItem } from '../../stores/types';
import { IResponseData } from '../../stores/types';
import Bullets from '../productInfo/bullets/bullets';
import Disclaimers from '../productInfo/disclaimer/disclaimers';
import ArticleSetItem from './articleSetItem/articleSetItem';
import { MainStore } from '../../stores/MainStore';

export function getVariantLabel(articleSetItem: IArticleSetItem | null, store?: MainStore): string {
    if (articleSetItem) {
        const colorText = articleSetItem.colorText?.trim();
        const sizeText = articleSetItem.sizeText?.trim();
        const modelText = articleSetItem.modelText?.trim();

        const returnValue: Array<string> = [];
        if (colorText && colorText.length > 0) {
            const colorLabel = store?.localization.generalL10n.textColor + ': ' + colorText;
            returnValue.push(colorLabel);
        }
        if (sizeText && sizeText.length > 0) {
            const sizeLabel = store?.localization.generalL10n.textSize + ': ' + sizeText;
            returnValue.push(sizeLabel);
        }
        if (modelText && modelText.length > 0) {
            const modelLabel = store?.localization.generalL10n.textModel + ': ' + modelText;
            returnValue.push(modelLabel);
        }
        if (returnValue.length > 0) {
            return returnValue.join(', ');
        } else {
            return '';
        }
    } else {
        return '';
    }

}
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const ProductInfoArticleSets = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const [renderAdditionalLayout, setRenderAdditionalLayout] = useState(false);
        const [detailsModalOpen, setDetailsModalOpen] = useState(false);
        const [openedArticleSetItem, setOpenedArticleSetItem] = useState<IArticleSetItem | null>(null);
        const [articleSetItemDetail, setArticleSetItemDetail] = useState<IResponseData | null>(null);
        const [productInfoContentHeight, setProductInfoContentHeight] = useState(0);

        const productContentRef = React.useRef<HTMLDivElement>(null);
        const productImageRef = React.useRef<HTMLImageElement>(null);
        const bulletsRef = React.useRef<Bullets>(null);
        const disclaimersRef = React.useRef<Disclaimers>(null);
        const [showContent, setShowContent] = useState(false);

        const structuredText = store!.fetchedData.shutterModule.productInfo.description.bullets;
        const orderedDisclaimers = store!.fetchedData.shutterModule.productInfo.description.orderedDisclaimers;

        function handleDetailsButtonClick(value: boolean, newArticleSetItem?: IArticleSetItem) {
            if (value) {
                if (newArticleSetItem) {
                    const savKeyVariants = newArticleSetItem.salesArticleVariantKey.split('-');
                    const masterArticleNo = newArticleSetItem.masterArticleNo;

                    store?.fetch.fetch(savKeyVariants[0], savKeyVariants[1], masterArticleNo, savKeyVariants[2]).then(result => {
                        if (result) {
                            store?.scrollLock.lock()
                            setOpenedArticleSetItem(newArticleSetItem);
                            setArticleSetItemDetail(result);
                            setDetailsModalOpen(value);
                        } else {
                            setArticleSetItemDetail(null);
                            setOpenedArticleSetItem(null);
                        }
                    });
                }
            } else {
                store?.scrollLock.unlock();
                setDetailsModalOpen(value);
            }
        }

        const productInfo: IProductInfo = {
            title: helper.decodeHTML(store?.localization.generalL10n.briefDescription),
            content: (
                <div>
                    {
                        structuredText && structuredText.id &&
                        <Bullets
                            ref={bulletsRef}
                            structuredTextId={structuredText.id} />

                    }
                    <div data-testid='title_article_set_consists_of' className={styles.title_set_consists_of}>
                        {helper.decodeHTML(store?.localization.generalL10n.articelSetConsistsOf)}
                    </div>
                    <div data-testid='article_sets_wrapper'>
                        {
                            store?.dataStore.shutterModule.productInfo.description.articleSetItemDescriptions?.map((articleSetItem, index) => {

                                return (
                                    <ArticleSetItem key={index} articleSetItem={articleSetItem}
                                        localization={store?.localization} showDetailsButton={true}
                                        variantLabel={getVariantLabel(articleSetItem, store)}
                                        handleDetailsButtonClick={() => handleDetailsButtonClick(true, articleSetItem)} />
                                );
                            })
                        }
                    </div>
                    {
                        orderedDisclaimers && orderedDisclaimers.length > 0 &&
                        <Disclaimers
                            ref={disclaimersRef}
                            disclaimers={orderedDisclaimers}
                            debuggerIsOn={store!.debuggerIsOn}
                            structuredTextExists={structuredText != null
                                && structuredText.id.length > 0}
                            isFromArticleSet={true}
                        />
                    }
                </div>
            ),
        }

        useEffect(() => {
            if (disclaimersRef && disclaimersRef.current) {
                disclaimersRef.current.update(orderedDisclaimers);

                disclaimersRef.current.turnOnDebugger(store!.debuggerIsOn);
            }

            if (bulletsRef && bulletsRef.current && structuredText && structuredText.id)
                bulletsRef.current.updateBullets(structuredText.id);
        });

        useEffect(() => {
            updateAdditionalLayout(true);
        }, [store?.fetchedData]);

        function updateAdditionalLayout(recalculateClientHeight = false) {
            if (productContentRef.current) {
                const content = productContentRef.current.firstChild as HTMLElement;
                if (content) {
                    // reset old styles:
                    productContentRef.current.classList.remove('pdp-removed_overflow');

                    if (recalculateClientHeight)
                        content.removeAttribute('style');

                    const isBigContent: boolean = content.clientHeight >= 552;
                    if (isBigContent) {
                        content.style.maxHeight = '511px';
                    } else {
                        productContentRef.current.classList.add('pdp-removed_overflow');
                    }
                    setRenderAdditionalLayout(isBigContent);
                    setProductInfoContentHeight(content.clientHeight);
                    setShowContent(false);
                }
            }
        }

        const claimRichTextId = store!.fetchedData.shutterModule.productInfo.claim?.text?.id;
        const emotionalTextRichtextId = store!.fetchedData.shutterModule.productInfo.emotionalText?.text?.id;
        return (
            <>
                <div data-testid='article_sets_product_info_container' className={styles.product_info_container}>
                    <div data-testid='article_sets_product_image' className={styles.product_image}>
                        <img alt={helper.decodeHTML(store!.mixedMediaStore.altTagForImages) ?? ''} 
                            ref={productImageRef} src={store!.mixedMediaStore.mainImage.fullPath} />
                    </div>
                    <div data-testid='article_sets_product_info_tabs' className={styles.product_info_tabs}>
                        {
                            store!.fetchedData.shutterModule.productInfo.claim &&
                            <div
                                className={classNames(styles.claim)}
                                data-testid='product_info_claim'
                            >
                                <RichText richTextId={claimRichTextId} />
                            </div>
                        }
                        {
                            store!.fetchedData.shutterModule.productInfo.emotionalText &&
                            <div
                                className={classNames(styles.emotionalText)}
                                data-testid='product_info_emotionaltext'
                            >
                                <RichText richTextId={emotionalTextRichtextId} />
                            </div>
                        }
                        <div data-testid='article_sets_product_info_tabs_header'
                            className={classNames(
                                styles.product_info_tabs_header,
                                { [styles.no_extras]: store!.fetchedData.shutterModule.productInfo.extras.length === 0 },
                                { [styles.no_specs]: store!.fetchedData.shutterModule.productInfo.details.length === 0 }
                            )}
                        >
                            <div
                                key={productInfo.title}
                                data-testid='info_tab'
                                className={classNames(styles.product_info_tab, {
                                    [styles.active]: productInfo.title
                                })}
                            >
                                {productInfo.title}
                            </div>
                        </div>
                        <div
                            id='product_info_content'
                            className={styles.product_info_content}
                            role='content'
                            ref={productContentRef}
                        >
                            {productInfo.content}
                        </div>
                    </div>
                </div>
                {
                    store && articleSetItemDetail && openedArticleSetItem &&
                    <DetailsModal
                        isOpen={detailsModalOpen}
                        onClose={() => handleDetailsButtonClick(false)}
                        localization={store.localization}
                        articleSetItemDetail={articleSetItemDetail}
                        variantLabel={getVariantLabel(openedArticleSetItem, store)}
                        detailPageUrl={openedArticleSetItem?.linkToDetailsPage}
                    />
                }
                <ProductAdditionalLayout
                    renderAdditionalLayout={renderAdditionalLayout}
                    productInfoContentHeight={productInfoContentHeight}
                    productContentRef={productContentRef}
                    showContent={showContent}
                    setShowContent={setShowContent}
                    isArticleSet={true}
                />
            </>
        );
    })
);

interface IProductInfo {
    title?: string;
    content: React.ReactNode;
}
