import {
    IResponseData,
    IPrice,
    IOrderModuleData,
    PiwikEvent,
    MetaEvent,
    LogoCreatorSuccessResponse,
    IBasketModalForLogoCreator,
    IScalePrice
} from '../stores/types';

// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var _paq: any;
// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var _fbq: any;

class TrackingHelper {
    private piwikProEnableEcommerceTracking: boolean;
    private enableMetaPixel: boolean;
    private esBrand: string;

    constructor(piwikProEnableEcommerceTracking: boolean, enableMetaPixel, esBrand: string) {
        this.piwikProEnableEcommerceTracking = piwikProEnableEcommerceTracking;
        this.enableMetaPixel = enableMetaPixel;
        this.esBrand = esBrand;
    }

    private getPriceValue(price: IPrice) {
        return price.grossValue;
    }

    private getScalePriceValue(scalePrices: IScalePrice[], quantity: number) {
        let result = 0;

        if (scalePrices && scalePrices.length > 0) {
            const scalePricesCopy = scalePrices.slice();
            scalePricesCopy.sort((a, b) => {
                let sortDirection = 0;
                if (a.quantity < b.quantity) {
                    sortDirection = -1;
                } else if (a.quantity > b.quantity) {
                    sortDirection = 1;
                }
                return sortDirection;
            });

            for (let i = 0; i < scalePricesCopy.length; i++) {
                if (quantity >= scalePricesCopy[i].quantity) {
                    result =  this.getPriceValue(scalePricesCopy[i].price);
                }
            }
        }

        return result;
    }

    public piwikTrackVisitedArticle(data: IResponseData, itemOrigin: string) {
        if (!this.piwikProEnableEcommerceTracking)
            return;

        const trackedData = [
            {
                sku: data.orderModule.articleInfo.salesArticleNo,
                name: data.orderModule.articleInfo.title,
                category: data.breadcrumb.category.navigationKeyPath.split('/'),
                price: data.orderModule.articlePrice.basicPrice.netValue,
                brand: (data.orderModule.articleInfo?.brand ?? '').toLocaleLowerCase() === this.esBrand ?
                    'Strauss' : 'other Brand',
                variant: data.orderModule.articleInfo.salesArticleVariantKey,
                customDimensions: {
                    '1': data.orderModule.articleInfo.masterArticleNo.toString(),
                    '2': data.variantSelection.selectedColor?.code ?
                        data.variantSelection.selectedColor.code.toString(): '',
                    '3': data.variantSelection.selectedSize?.name,
                    '4': data.variantSelection.selectedModel?.code ?
                        data.variantSelection.selectedModel.code.toString() : '',
                    '5': 'MODAL_ORDER/' + itemOrigin,
                }
            }
        ];

        if (typeof _paq !== 'undefined')
            _paq.push([PiwikEvent.EcommerceProductDetailView, trackedData]);
    }

    public metaTrackVisitedArticle(data: IResponseData) {
        if (!this.enableMetaPixel)
            return;

        const trackedData = [
            {
                content_name: data.orderModule.articleInfo.title,
                content_category: data.breadcrumb.category.navigationKeyPath.split('/'),
                content_ids: [data.orderModule.articleInfo.salesArticleVariantKey],
                content_type: 'product',
                value: this.getPriceValue(data.orderModule.articlePrice.basicPrice),
                currency: data.orderModule.articlePrice.currency.isoCode,
            }
        ];

        if (typeof _fbq !== 'undefined')
            _fbq.push(['track', MetaEvent.ViewContent, trackedData]);
    }

    public metaTrackAddToCart(orderModule: IOrderModuleData, quantity: number) {
        if (!this.enableMetaPixel)
            return;

        let price = this.getPriceValue(orderModule.articlePrice.basicPrice);
        if (orderModule.articlePrice.scalePrices) {
            const scalePriveValue = this.getScalePriceValue(orderModule.articlePrice.scalePrices, quantity);
            if (scalePriveValue > 0) {
                price = scalePriveValue;
            }
        }
        const value = price * quantity;

        const trackedData = [
            {
                contents: [
                    {
                        id : orderModule.articleInfo.salesArticleVariantKey,
                        quantity,
                    }
                ],
                content_type: 'product',
                value,
                currency: orderModule.articlePrice.currency.isoCode,
            }
        ];

        if (typeof _fbq !== 'undefined')
            _fbq.push(['track', MetaEvent.AddToCart, trackedData]);
    }

    public metaTrackAddToCartSmake(lcResponse: LogoCreatorSuccessResponse, lcBasketData: IBasketModalForLogoCreator) {
        if (!this.enableMetaPixel)
            return;

        const contents = lcResponse.articles.map((el) => {
            return { id: el.savKey, quantity: el.quantity };
        })

        let value = lcResponse.reporting.price.value;
        if (lcBasketData.sizesWithPrice) {
            for (let i = 0; i < lcBasketData.sizesWithPrice.length; i++) {
                const sizeWithPrice = lcBasketData.sizesWithPrice[i];

                for (let j = 0; j < contents.length; j++) {
                    const addToCartItem = contents[j];

                    if (sizeWithPrice.savKey === addToCartItem.id) {
                        value += sizeWithPrice.netPrice + addToCartItem.quantity;
                    }
                }
            }
        }

        const trackedData = [
            {
                contents,
                content_type: 'product',
                value,
                currency: lcResponse.reporting.price.currency,
            }
        ];

        if (typeof _fbq !== 'undefined')
            _fbq.push(['track', MetaEvent.AddToCart, trackedData]);
    }
}

export default TrackingHelper;
